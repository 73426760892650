import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
  } from "@material-tailwind/react";
   
  export default function CardHome({title, img, description, link}) {
    return (
      <Card className="w-auto my-10">
        <CardHeader className="relative h-56">
          <img
            src={img}
            alt="img-blur-shadow"
            className="h-full w-full"
          />
        </CardHeader>
        <CardBody className="text-center">
          <Typography variant="h5" className="mb-2">
            {title}
          </Typography>
          <Typography>
           {description}
          </Typography>
        </CardBody>
        <CardFooter divider className="flex items-center justify-between py-3">
          {/* <Typography variant="small" className='bg-primary text-white py-1 px-2 rounded-full' >Show</Typography> */}
          <a href={link} className="bg-primary text-white  py-1 px-2 rounded-full"> Show </a>
        </CardFooter>
      </Card>
    );
  }