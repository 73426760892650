import { Button } from "@material-tailwind/react";
import Layout from "../../components/layout";
import { Logo } from "../../assets";
import Hero from "./components/Hero";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Skill from "./components/Skill";
import Education from "./components/Education";
export default function App() {
  return (
      <Layout>

      {/* Hero */}
        <Hero/>
        <Experience/>
        <Portfolio/>
        <Skill/>
        <Education/>
      </Layout>
    
  )
}