import React from 'react'
import { English, Indo, Stikom } from '../../../assets'

export default function Education() {
  return (
    <div  id='education' className='lg:py-12 px-8 pt-8' >
        <div className='flex flex-col md:flex-row w-full md:gap-x-56' >
            <div className='py-4' >
                <h2 className=' font-bold text-2xl' >Education </h2>
                <img src={Stikom}  />
            </div>
            <div className='py-4' >
                <h2 className=' font-bold text-2xl' >Language </h2>
                <img src={English}  />
                <img src={Indo}  />
            </div>
        </div>

    </div>
  )
}
