import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import '../styles/styles.css';
import { Pagination } from "swiper";
import CardHome from './CardHome';
import {Balievent, MobileUsadha, Pono, Portfolio as PortfolioImg, Sbw, Simpeltab, Usadha} from "../../../assets";
export default function Portfolio() {

    const [isMobile, setIsMobile] = useState('mobile');
    const [slidesPerView, setSlidesPerView] = useState(4);

    useEffect(() => {
      function handleResize() {
        if(window.innerWidth >= 768){
          setIsMobile('desktop');
          setSlidesPerView(3)
        }else if (window.innerWidth >= 640){
          setIsMobile('tablet');
           setSlidesPerView(2)
        }else{
          setIsMobile('mobile');
          setSlidesPerView(1)
        }
      }

      handleResize(); // call initially to set the initial state based on the current size

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const portfolioData = [
      {
        img : MobileUsadha,
        title : 'App Usadha Bhakti',
        description : 'App android penjualan minyak urut',
        link : 'https://play.google.com/store/apps/details?id=com.minyakbelog'
      },
      {
        img : Usadha,
        title : 'Website Usadha Bhakti',
        description : 'Website Penjualan Minyak',
        link : 'https://www.belogherbal.com/'
      },
      {
        img : Simpeltab,
        title : 'Simpeltab',
        description : 'Sistem pelaporan air PDAM',
        link : 'https://play.google.com/store/apps/details?id=com.simpeltab'
      },
      {
        img : Pono,
        title : 'Yoga Training Teacher Bali ',
        description : 'SEO Project ',
        link : 'https://powerofnowoasis.com/'
      },
      {
        img : Sbw,
        title : 'Rental Motor Murah SBW',
        description : 'Rental Motor Murah di bali SEO  ',
        link : 'http://rentalmotormurahbali.com/'
      },
      {
        img : Balievent,
        title : 'My Bali Event Planner',
        description : 'Website Wedding Planner',
        link : 'https://mybalieventsplanner.com/'
      }
    ]

  return (
    <div>
         <div className='lg:py-12 px-8 pt-8' >
            <h2 className='text-2xl font-bold' >Portfolio</h2>
            <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper relative"
            >
                {portfolioData && portfolioData.map((item) => {
                  return (
                    <SwiperSlide className='relative'>
                        <CardHome title={item.title} img={item.img} description={item.description} link={item.link} />
                    </SwiperSlide>
                  )
                })}
            </Swiper>
        </div>
    </div>
  )
}
