import React from 'react'
import { Bigcommerce, Codeiginiter, Css, Laravel, Marketing, Next, Seo, Shopify, Tailwind, Wp, React as Reacticon, Bootstrap, Js, Html} from '../../../assets'

export default function Skill() {
  return (
    <div className='lg:py-12 px-8 pt-8 bg-primary' id='skill' >
      <h2 className='text-white font-bold text-2xl' >Skill </h2>
      <div class="grid grid-cols-3 gap-y-2 justify-between md:justify-normal md:grid-cols-7 md:gap-2 py-8  ">
        <img src={Laravel} />
        <img src={Next} />
        <img src={Bigcommerce} />
        <img src={Tailwind} />
        <img src={Codeiginiter} />
        <img src={Css} />
        <img src={Seo} />
        <img src={Wp} />
        <img src={Shopify} />
        <img src={Marketing} />
        <img src={Reacticon} />
        <img src={Bootstrap} />
        <img src={Js} />
        <img src={Html} />
      </div>
    </div>
  )
}
