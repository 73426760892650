import React from 'react'
import { Code, Email, Github, Gmail, Instagram, Linkedin, Location, Profile, User } from '../../../assets'
import { Button } from '@material-tailwind/react'

export default function Hero() {
  return (
    <div id='about' className='bg-secondary-transparant bg-opacity-[8%]'>
         <div className='flex flex-col lg:flex-row items-center gap-x-28  gap-y-4 lg:gap-y-0 py-10 lg:py-16' >
            <img src={Profile} className='w-52 h-52 lg:w-80 lg:h-80 object-cover  rounded-md '/>

            <div className='text-center flex flex-col gap-y-8 items-center px-2' >
                <h1 className='font-bold  text-2xl lg:text-5xl '  >Transforming your business through digital excellence</h1>
                <p className='text-lg '>We can help you to make website for your business</p>
                <div className='max-w-xs text-center' >
                    <a href='https://api.whatsapp.com/send?phone=6282145931513' >
                      <Button> <span className='flex items-center justify-center gap-x-2' >Chat me on Email <img src={Email} className='w-8 z' /> </span> </Button>
                    </a>
                </div>
            </div>
        </div>
        <hr/>
        <div>
          <div class="grid grid-cols-1 gap-y-4 justify-between lg:justify-normal lg:grid-cols-4 lg:gap-4 py-8 lg:py-12 px-8 ">
            <div className='lg:h-36 gap-y-4 lg:gap-y-0 flex flex-col lg:justify-between'>
              <p className='text-sm' >I’m a website developer more than 3 years experience</p>
              <p className='flex items-center gap-x-2' ><img src={Code} /> <span>Full-Stack Developer</span> </p>
            </div>
            <div className='lg:h-36 text-base font-bold' >
              <p>++ <br/> Project</p>
            </div>
            <div className='lg:h-36 flex flex-row  lg:flex-col justify-between'>
              <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                <img src={User} /> <span>Fajar</span>
              </p>
              <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                <img src={Location} /> <span>Bali</span>
              </p>
              <a href='https://github.com/fajarprayoga' >
                <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                  <img src={Github} /> <span>Github</span>
                </p>
              </a>
            </div>
            <div className='lg:h-36 flex flex-row lg:flex-col gap-y-4 lg:gap-y-0 justify-between' >
                <a href='fajarprayoga23@gmail.com' >
                  <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                    <img src={Gmail} /> <span>Gmail</span>
                  </p>
                </a>
                <a href='https://www.instagram.com/fajarprayoga23/' >
                  <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                    <img src={Instagram} /> <span>Instagram</span>
                  </p>
                </a>
                <a href='https://www.linkedin.com/in/fajar-prayoga/' >
                  <p className='flex flex-col md:flex-row  gap-x-2 items-center  ' >
                    <img src={Linkedin} /> <span>Linkedin</span>
                  </p>
                </a>
            </div>
          </div>
        </div>
    </div>
  )
}
