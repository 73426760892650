import React from 'react'

const List = ({title, data}) => {
    return (
        <div className='py-4' >
            <h2 className='font-bold p-3 text-white bg-primary inline-block rounded-full text-xs' >{title}</h2>
            {data && data.map((item) => {
                return (
                    <div className='py-2' >
                        <h3 className='text-md font-bold' >{item.title}</h3>
                        <p className='text-sm' >{item.date}</p>
                        <p className='text-md'> {item.company}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default function Experience() {

    const list1 = [
        {title : 'Full Stack Developer', date : '2019-2021', company : 'Bali Algorithms'},
        {title : 'Programmer and Seo', date : '2021-2022', company : 'Wonderworxs'},
        {title : 'Junior Backend Developer ', date : '2022-2023', company : 'Aidhub.org'},
    ]

    const list2 = [
        {title : 'Programmer and Seo', date : '2019-2021', company : 'Topdocode'},
    ]

  return (
    <div className='lg:py-12 px-8 pt-8' id='experience' >
        <h2 className='text-2xl font-bold' >Experience</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-y-4 justify-between md:justify-normal md:gap-2   ">
            <List title = "Full Time Working" data={list1}/>
            <List title = "Frelance" data={list2}/>
        </div>
    </div>
  )
}
