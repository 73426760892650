import React from 'react'

export default function Layout({children}) {
  return (
    <div className='max-w-screen-2xl mx-auto pt-10' >
      {children}

      <hr/>
      <footer className='py-4' >
        <p className='block text-center text-xs' >@Copyright 2023</p>
      </footer>
    </div>
  )
}
